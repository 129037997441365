import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Content = () => {
  return (
    <Box>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
        }}
        gutterBottom
        color={'textSecondary'}
        align={'center'}
      >
        
      </Typography>
      {/* <Typography variant="h4" align={'center'} fontWeight={700} gutterBottom>
      Co-Scholastic
      </Typography> */}
    </Box>
  );
};

export default Content;