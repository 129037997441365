import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
//import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


const Headline = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
       
      </Box>
      <Divider sx={{ marginY: 4 }} />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={8}>
          <Box marginBottom={3}
            href="https://mui.com/store/items/the-front-landing-page/"
          >
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
            Co-Scholastic Programmes<br />
            </Typography>
            {/* <Typography >
            The school organises Co-curricular Activities (CCA) for Classes 1-5 giving them plethora of activities to bring out their innate potential.
            </Typography> */}
          </Box>
          <Box marginBottom={3}>
          <Typography variant={'h5'} fontWeight={700} gutterBottom>
          CCA - CO-CURRICULAR ACTIVITIES AND INTER-HOUSE COMPETITIONS <br/> Classes 1-5<br />
            </Typography>
            <Typography align='justify'>
            National Public School Yeshwanthpur focuses on the holistic development of the students. An integral part of the curriculum comprises of a wide spectrum activities. Co-curricular Activities (CCA) ensures the harmonious growth of personality in children. CCA are an extension of the formal learning experiences and play a vital role in supplementing the academic activities. They develop interest and help to improve language skill, Communicative skill, Theatrical skill and the artistic skill of the child. CCA also help to improve analytical thrust for knowledge competitive spirit, value orientation, leadership teamwork and various other facets of life. To inculcate all the said qualities, quiz, recitation, extempore speech, elocution, debate, mono act, fancy dress, competitions are conducted as per a well-planned CCA calendar throughout the academic year.  Co-curricular Activities cover all the areas which ensure development of holistic personality providing ample opportunities to the students to develop their inborn talents and abilities. Every child gets an opportunity to discover different facets of their personality. These activities prepare the students’ for an ever-changing and challenging world. 
            </Typography>
           < Typography variant={'h5'} fontWeight={700} gutterBottom>
         
<br/><br/>CLUBS/ASSOCIATIONS AND INTER-HOUSE COMPETITIONS <br/> Classes 6-12
<br />

            </Typography>
            <Typography  ariant={'h5'} fontWeight={700} gutterBottom >
            <br/>The following Associations facilitate Inter-house competition<br/>
            </Typography>
           < Typography align="justify">
         
           Literary Association – Junior and Senior<br></br>
           Mathematics Association – Junior and Senior<br></br>
           Science Association – Junior and Senior<br></br>
           Humanities Association – Junior and Senior<br></br>
           Quiz Association – Junior and Senior<br></br>
           Cyber Association-Junior and Senior<br></br>
           Eco Association – Junior and Senior<br></br>
           Commerce Association – Senior<br></br>
           EK Bharath Shresht Bharat(EBSB) Association-Junior and Senior<br></br>
           <br></br>
           </Typography>
            <Typography  ariant={'h5'} fontWeight={700} gutterBottom >
            <br/>The following CLUBS facilitate Inter-house competition<br/>
            </Typography>
            < Typography align="justify">
           
           ECO Club
           <br></br>
            Cultural Heritage Club
             <br></br>
            Health & Wellness Club
            <br></br>
            YUVA Tourism Club
            <br></br>
            Expression Series Club
            <br></br>
            Electoral Club
            <br></br>
            Robotics Club 
            <br></br>

<br />
            </Typography>
            <Typography  align='justify'>
            The establishment of extra-curricular programmes is paramount in fostering personal growth and promoting leadership. National Public School Yeshwanthpur encourages students to pursue their interests and passions outside of the classroom. There are opportunities like debates, essay writing, quizzes, music, dance and drama, art and painting, educational tours etc. Our extra-curricular programme runs in conjunction with whole class participation in certain events. Most Saturdays, events are organised allowing students to work with other students of different ages, encouraging co-operation, team spirit and healthy competition.
            </Typography>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              <br/>INTER-HOUSE SPORTS COMPETITIONS <br/> Classes 1-12
            </Typography>
           
              <Typography align="justify">
             <br/> Sports is an integral part of our curriculum which is designed to engage students in movement skills and to learn strategies in various sports. Sports Education is offered through multiple avenues ranging from dedicated PE sessions and After School Sports Activities.
             <br/>National Public School Yeshwanthpur provides well equipped multi sporting facilities that include Cricket, Basketball, Throwball, Volleyball, Badminton, Kho-Kho, Atheletics and indoor games such as Chess, Carrom etc. The school not only develops student's physical abilities but also inculcates a sense of good sportsmanship in all students who further develop different levels of experience from the game.
             <br/>Inter-house sports competitions are conducted periodically to develop physical, emotional and social skills amongst students and to help them acquire a sense of personal responsibility for lifelong health.

            
              </Typography>
            
            {/* <Typography variant={'h5'} fontWeight={700} gutterBottom>  
              <br />ASSOCIATIONS
            </Typography>
            <Typography >
            Activity clubs provide a platform wherein students participate eagerly and perform with enthusiasm. The school has a Literary Association, Reading Club, Art Club, Quiz Association, MUN Association, Commerce Association, Cultural Association etc., provide avenues for the blossoming of artistic talent. Every child who has the interest and urge to excel is given ample opportunity and chance to explore and enhance his/her potential.
            </Typography> */}
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
            
              <br /> LIBRARY
            </Typography>
            <Typography  align='justify'>
            The School Library recognises their Avid Readers every month, thus encouraging and promoting Reading skills in every student. The School Magazine Repertoire is an annual publication. Students compile and integrate the text matter and are given the opportunity to enhance their creativity through their school magazine.
            </Typography>
            
            {/* <Typography variant={'h5'} fontWeight={700} gutterBottom>
              <br />INTER-HOUSE COMPETITIONS
            </Typography>
            <Typography  align='justify'>
            Inter-house competitions are designed to explore and to fulfill the unique ability found in every child. A variety of competitions like Role plays, Extempore, Fun with Science, Dance, Music (Vocal), Maths quiz, English skit, Hindi Speech, English Debate and Quiz are conducted regularly.
            </Typography> */}
            
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              <br />EXTRA-CURRICULAR ACTIVITIES
            </Typography>
            
            <Typography  align='justify'>
            Extracurricular activities are an essential element of school life. Yoga, Music, Craft, Art, Dance and a host of other such activities are woven into the framework of the curriculum to expose students to various other pursuits other than academics. Students have access to news around them through the NIE programme which the school has subscribed.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card} bgcolor={'primary.main'}>
              </Box>
            </Grid>
            <Box>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'column', sm: 'row' }}
              >
                <Box>
              
                  {/* <Box
                    component={Button}
                    variant="outlined"
                    color="primary"
                    size="large"
                    marginRight={2}
                    href="/scholastic"
                  >
           Scholastic
                  </Box> */}
                </Box>
                {/* <Box
                  component={Button}
                  variant="outlined"
                  color="primary"
                  size="large"
                  marginLeft={4}
                  href="/co-scholastic"
                >
           Co-Scholastic
                </Box> */}
              </Box>
            </Box>  
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};


export default Headline;