import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Content, Footer, Headline } from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const Faq = () => {
  const theme = useTheme();
  return (
    <Main>
      <Content />
      <Container maxWidth={0.9} marginTop={-5} >
          <Footer />
        </Container>
        <Content />
     
          <Container>
            <Headline />
          </Container>
         
 
        {/* <Container maxWidth={800}>
          <Content />
        </Container>
        <Box bgcolor={theme.palette.alternate.main}>
          <Container>
            <Footer />
          </Container>
        </Box> */}
     
    </Main>
  );
};

export default Faq;
